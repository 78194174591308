import React, { useState, useEffect } from 'react';

const ScrollDots = ({ sections }) => {
    const [scrollProgress, setScrollProgress] = useState(0);
    const [debugInfo, setDebugInfo] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const docHeight = document.documentElement.scrollHeight;
            const totalScroll = docHeight - windowHeight;
            const progress = (scrollPosition / totalScroll) * 100;
            setScrollProgress(progress);
          
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Call once to set initial state
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="fixed left-0 top-0 h-full z-50 flex items-center">
            <div className="h-full w-1 bg-black-500 relative">
                <div
                    className="absolute top-0 left-0 right-0 bg-white transition-all duration-300 ease-out"
                    style={{ height: `${scrollProgress}%` }}
                />
            </div>
            <div className="ml-2 text-white text-xs">{debugInfo}</div>
        </div>
    );
};

export default ScrollDots;