import React from 'react';
import { HoverEffect } from "./ui/card-hover-effect.tsx";
import { FaRobot, FaCompass, FaRandom, FaDollarSign, FaHandsHelping, FaBolt } from 'react-icons/fa';


export function Cards() {
    return (
        <div className="max-w-7xl mx-auto px-4"> {/* Increased max-width */}
            <HoverEffect items={projects} />
        </div>
    );
}

export const projects = [
    {
        title: "Intuitive",
        description:
            "Easy to use with multiple key shortcuts and no unnecessary settings",
        icon: <FaCompass className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
    },
    {
        title: "Adaptive",
        description:
            "Multiple execution modes to fit any trading style or strategy",
        icon: <FaRandom className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
        
    },
    {
        title: "Blazing Fast",
        description:
            "1-click trade executions at exact price levels with millisecond accuracy",
        icon: <FaBolt className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />

        
    },
    {
        title: "Automation",
        description:
            "Pre-plan trades and secure your profits without constant monitoring",
        icon: <FaRobot className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />

    },
    {
        title: "Liquidity Indicator",
        description:
            "Make informed decisions aligned with market intentions and protect capital",
        icon: <FaDollarSign className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />

    },
    
    
    {
        title: "Affordable",
        description:
            "No subscription, fully supporting you to become profitable",
        icon: <FaHandsHelping className="h-5 w-5 sm:h-6 sm:w-6 text-gray-500" />
       
    },
];