import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { TextGenerateEffect } from "./ui/text-generate-effect.tsx";
import {
    DollarSign, Settings, Target, Keyboard, BarChart2, Minimize, Globe,
    HelpCircle, LineChart
} from 'lucide-react';

const ARROW_SYMBOL = String.fromCodePoint(0x2022);  // Unicode for bullet point

const words1 = "Liquidity Indicator & Settings";
const words2 = " 'always aware of targets' ";

function Text3({ isVisible, inView }) {
    const [key, setKey] = useState(0);

    useEffect(() => {
        if (inView) {
            setKey(prevKey => prevKey + 1);
        }
    }, [inView]);

    return (
        <div className="flex flex-col justify-center items-center relative z-10">
            {isVisible && (
                <div className="text-white text-shadow-lg">
                    <TextGenerateEffect key={`text1-${key}`} words={words1} />
                    <TextGenerateEffect key={`text2-${key}`} words={words2} />
                </div>
            )}
        </div>
    );
}

export default function CombinedFeatures5() {
    const [activeSection, setActiveSection] = useState(0);
    const [activeTabs, setActiveTabs] = useState({});

    const [ref1, inView1] = useInView({ threshold: 0.8 });
    const [ref2, inView2] = useInView({ threshold: 0.8 });
    const [ref3, inView3] = useInView({ threshold: 0.8 });
    const [ref4, inView4] = useInView({ threshold: 0.8 });

    useEffect(() => {
        if (inView1) setActiveSection(0);
        else if (inView2) setActiveSection(1);
        else if (inView3) setActiveSection(2);
        else if (inView4) setActiveSection(3);
    }, [inView1, inView2, inView3, inView4]);

    const sections = [
        {
            type: 'title',
            content: <Text3 isVisible={true} inView={inView1} />
        },
        {
            type: 'image',
            content: {
                src: "/images/liquiditysettings.png",
                alt: "Liquidity & Settings"
            }
        },
        {
            type: 'text',
            content: [
               
                {
                    title: "Liquidity Indicator",
                    items: [
                        { type: 'title', icon: <BarChart2 size={16} />, text: "Daily Liquidity" },
                        { text: "Previous Daily Highs (PDHs) and Previous Daily Lows (PDLs) are the most simple and effective targets" },
                        { text: "The PDHs and PDLs taken in the past 10 days can provide a direction of price" },
                        { text: "Price can either move into a trend, be in a pullback or stay sideways" },
                        { text: "Clear difference between PDHs and PDLs taken means a HTF trend and potential over extension of price" },
                        { text: "Negligible between PDHs and PDLs taken means a sideways price action or a HTF pullback" },
                        { text: "From a daily perspective, price will most likely target PDH or PDL, take both, or very rare to stay contained" },

                        { type: 'title', icon: <Globe size={16} />, text: "Asia Liquidity" },
                        { text: "Asia Range contains the orders placed from the start of the day, and before Frankfurt and London opening" },
                        { text: "In 80% of cases Asia High and Low are both liquidated in the same day" },

                        { type: 'title', icon: <LineChart size={16} />, text: "Chart Information" },
                        { text: "Liquidity information in text format at the top of the chart to stay aware regardless of the used timeframe" },
                        { text: "Liquidity information in icon format, marking the PDH/PDL and Asia High/Low2 for the last day" },
                        { text: "Arrows and X used for levels that were taken" },

                        { type: 'title', icon: <HelpCircle size={16} />, text: "Utilization" },
                        { text: "Nothing in trading is 100%" },
                        { text: "Use this as a confluence to increase your stoploss protection, by not trading against the liquidity" },
                        { text: "If this doesn't work alongside your strategy, it can be disabled from Settings" },
                    ]
                },
                {
                    title: "App Settings",
                    items: [
                        { type: 'title', icon: <DollarSign size={16} />, text: "Risk" },
                        { text: "Input Risk in your account currency (USD placeholder)" },
                        { text: "This risk cannot be exceeded" },
                        { text: "Auto-calculates account currency into lots" },

                        { type: 'title', icon: <Target size={16} />, text: "Take Profit" },
                        { text: "TP1 level in RR" },
                        { text: "TP1 % (Partials) level in %" },
                        { text: "TP2 level in RR" },
                        { text: "Can be further adjusted after placement using Z+KEY" },
                        { text: "Zero to disable TP levels" },

                        { type: 'title', icon: <Minimize size={16} />, text: "Break Even" },
                        { text: "BE level in RR" },
                        { text: "Can be further adjusted after placement using assigned KEY" },
                        { text: "Triple press assigned KEY to instant move to BE" },
                        { text: "Zero to disable BE level" },

                        

                        { type: 'title', icon: <Keyboard size={16} />, text: "Key Shortcuts" },
                        { text: "TAB - Enter Limit Execution Mode (ESC/TAB for exit)" },
                        { text: "SHIFT - Enter Fast Execution Mode (ESC/SHIFT for exit)" },
                        { text: "R - Toggle ON/OFF Regular Execution Mode" },
                        { text: "D - Toggle ON/OFF Dynamic Execution Mode" },
                        { text: "Double X to execute trades in Regular/Dynamic Modes (R/D)" },
                        { text: "Triple CAPSLOCK to close all positions (Panic Mode)" },

                        { type: 'title', icon: <Settings size={16} />, text: "Other" },
                        { text: "DynSL input in pips for Dyanmic Mode (D)" },
                        { text: "Hide/Show the helper with key shortcuts" },
                        { text: "Use/Revert our chart Template" },
                        { text: "Hide/Show the Liquidity Indicator" }

                    ]
                },
            ]
        }
    ];

    const flattenedSections = sections.flatMap(section =>
        section.type === 'text' ? section.content : [section]
    );

    // Set the first tab as active by default for each section
    useEffect(() => {
        const defaultTabs = {};
        flattenedSections.forEach((section, index) => {
            if (!section.type) {
                defaultTabs[index] = 0;
            }
        });
        setActiveTabs(defaultTabs);
    }, []);

    const handleTabClick = (sectionIndex, tabIndex) => {
        setActiveTabs(prev => ({
            ...prev,
            [sectionIndex]: tabIndex
        }));
    };

    return (
        <div className="min-h-screen text-white p-4 sm:p-8 flex flex-col items-center justify-center relative z-10 mt-24 sm:mt-32 md:mt-40">
            <div className="w-full max-w-6xl">
                <div className="flex flex-col space-y-8 sm:space-y-20">
                    {flattenedSections.map((section, index) => (
                        <div
                            key={index}
                            ref={[ref1, ref2, ref3, ref4][index]}
                            className={`transition-all duration-500 ${activeSection === index
                                    ? 'opacity-100 scale-105 sm:scale-120'
                                    : 'opacity-30 scale-100'
                                } ${index > 0 ? '-mt-4 sm:mt-0' : ''}`}
                        >
                            {section.type === 'title' && (
                                <div className="flex items-center justify-center mb-2 sm:mb-6 mt-8 sm:mt-16 ">
                                    {section.content}
                                </div>
                            )}
                            {section.type === 'image' && (
                                <div className="flex justify-center items-center">
                                    <img
                                        src={section.content.src}
                                        alt={section.content.alt}
                                        className="max-w-full max-h-[60vh] sm:max-h-[70vh] object-contain rounded-2xl"
                                    />
                                </div>
                            )}
                            {!section.type && (
                                <div className="w-full flex flex-col items-center mb-4 md:mb-8">
                                    <h3 className="text-sm font-bold text-center mb-4 text-gray-400">{section.title}</h3>
                                    <div className="inline-block w-full sm:w-auto mx-5 sm:mx-0">
                                        <div className="bg-black bg-opacity-50 p-5 sm:p-6 rounded-xl">
                                            <div className="flex mb-4 space-x-2 overflow-x-auto">
                                                {section.items.filter(item => item.type === 'title').map((tab, tabIndex) => (
                                                    <button
                                                        key={tabIndex}
                                                        onClick={() => handleTabClick(index, tabIndex)}
                                                        className={`px-2 py-1 text-xs rounded-lg flex-1 h-16 flex flex-col items-center justify-center ${activeTabs[index] === tabIndex
                                                            ? 'bg-indigo-600 text-white border border-gray-800'
                                                            : 'bg-black bg-opacity-50 text-gray-300 border border-gray-800'
                                                            }`}
                                                    >
                                                        {tab.icon}
                                                        <span className="text-center leading-tight mt-1">{tab.text}</span>
                                                    </button>
                                                ))}
                                            </div>
                                            <ul className="list-disc list-outside text-xs sm:text-sm md:text-base lg:text-sm text-left space-y-0.5 sm:space-y-0.5 ml-5">
                                                {section.items.map((item, itemIndex) => {
                                                    if (item.type === 'title') {
                                                        return null; // We've moved titles to tabs
                                                    }
                                                    const currentTabIndex = section.items.slice(0, itemIndex).filter(i => i.type === 'title').length - 1;
                                                    if (currentTabIndex !== activeTabs[index]) {
                                                        return null; // Only show items for the active tab
                                                    }
                                                    return (
                                                        <li key={itemIndex} className="pl-0.1">
                                                            <span>{item.text}</span>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}