import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Background from './components/Background';
import { Text } from './components/Text';
import { Text2 } from './components/Text2';
import { Text3 } from './components/Text3';
import { Text4 } from './components/Text4';
import { Text5 } from './components/Text5';
import Meta from './components/Meta';
import { Cards } from './components/Cards';
import Features1 from './components/Features1';
import Features2 from './components/Features2';
import StripePayment from './components/StripePayment';
import CombinedFeatures from './components/CombinedFeatures';
import CombinedFeatures1 from './components/CombinedFeatures1';
import CombinedFeatures2 from './components/CombinedFeatures2';
import CombinedFeatures3 from './components/CombinedFeatures3';
import CombinedFeatures4 from './components/CombinedFeatures4';
import CombinedFeatures5 from './components/CombinedFeatures5';
import ScrollDots from './components/ScrollDots';
import Logos from './components/Logos';
import Footer from './components/Footer';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import Contact from './components/Contact';
import AdminDashboard from './components/AdminDashboard';
import FacebookPixel from './components/FacebookPixel';
import Sitemap from './components/Sitemap';
import GoogleAnalytics from './components/GoogleAnalytics';
import BlogPost from './components/BlogPost';



function MainContent() {
    const [visibleSections, setVisibleSections] = useState({
        hero: false,
        text2: false,
        text3: false,
        text4: false,
        text5: false,
    });

    const heroRef = useRef(null);
    const text2Ref = useRef(null);
    const text3Ref = useRef(null);
    const text4Ref = useRef(null);
    const text5Ref = useRef(null);

    useEffect(() => {
        document.documentElement.classList.add('dark');

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                if (entry.target.id === 'hero') {
                    setVisibleSections(prev => ({ ...prev, hero: entry.isIntersecting }));
                } else if (entry.target.id === 'text2') {
                    setVisibleSections(prev => ({ ...prev, text2: entry.isIntersecting }));
                } else if (entry.target.id === 'text3') {
                    setVisibleSections(prev => ({ ...prev, text3: entry.isIntersecting }));
                } else if (entry.target.id === 'text4') {
                    setVisibleSections(prev => ({ ...prev, text4: entry.isIntersecting }));
                } else if (entry.target.id === 'text5') {
                    setVisibleSections(prev => ({ ...prev, text5: entry.isIntersecting }));
            }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        if (heroRef.current) observer.observe(heroRef.current);
        if (text2Ref.current) observer.observe(text2Ref.current);
        if (text3Ref.current) observer.observe(text3Ref.current);
        if (text4Ref.current) observer.observe(text4Ref.current);
        if (text5Ref.current) observer.observe(text5Ref.current);

        return () => {
            if (heroRef.current) observer.unobserve(heroRef.current);
            if (text2Ref.current) observer.unobserve(text2Ref.current);
            if (text3Ref.current) observer.unobserve(text3Ref.current);
            if (text4Ref.current) observer.unobserve(text4Ref.current);
            if (text5Ref.current) observer.unobserve(text5Ref.current);
        };
    }, []);

    const sections = [
        'hero',
        'meta',
        'logos',
        'cards',
        'features',
        'text2',
        'features4',
        'features3',
        'features5',
        'text3',
        'payment',
        'text4',
        'features1',
        'features2',
        'text5',
        'footer',
    ];

    return (
        <div className="app bg-black min-h-screen text-white">
            <Helmet>
                <title>Flow Execution - MetaTrader 5 Most Advanced Trade Assistant</title>
                <meta name="description" content="Cutting-edge execution assistant app for MetaTrader 5. Advanced execution modes, trade automation, risk management, liquidity indicator." />
                <meta name="keywords" content="trade assistant, trading assistant, Flow Execution, MT5, MetaTrader 5, forex trading, automated trading, risk management, trading tools" />
                <meta property="og:title" content="Flow Execution - MetaTrader 5 Most Advanced Trade Assistant" />
                <meta property="og:description" content="Cutting-edge execution assistant app for MetaTrader 5. Advanced execution modes, trade automation, risk management, liquidity indicator." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.flowexecution.com" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Flow Execution: Skyrocket Your MetaTrader 5 Trading" />
                <meta name="twitter:description" content="Cutting-edge execution assistant app for MetaTrader 5. Advanced execution modes, trade automation, risk management, liquidity indicator." />
            </Helmet>
            <FacebookPixel pixelId="2202048696818146" />
            <GoogleAnalytics measurementId="G-726J27KY1H" />
            <Background />
            <ScrollDots sections={sections} />
            <div id="hero" ref={heroRef}>
                <Text isVisible={visibleSections.hero} />
            </div>
            <div id="meta">
                <Meta />
            </div>
            <div id="logos">
                <Logos />
            </div>
            <div id="cards">
                <Cards />
            </div>
            <div id="combinedfeatures">
                <CombinedFeatures />
            </div>
            
            <div id="text2" ref={text2Ref}>
                <Text2 isVisible={visibleSections.text2} />
            </div>
            <div id="combinedfeatures1">
                <CombinedFeatures1 />
            </div>
            <div id="combinedfeatures2">
                <CombinedFeatures2 />
            </div>
            <div id="combinedfeatures4">
                <CombinedFeatures4 />
            </div>
            <div id="combinedfeatures3">
                <CombinedFeatures3 />
            </div>
            <div id="combinedfeatures5">
                <CombinedFeatures5 />
            </div>
            <div id="text3" ref={text3Ref}>
                <Text3 isVisible={visibleSections.text3} />
            </div>
            <div id="payment">
                <StripePayment />
            </div>
            <div id="text4" ref={text4Ref}>
                <Text4 isVisible={visibleSections.text4} />
            </div>
            <div id="features1">
                <Features1 />
            </div>
            <div id="features2">
                <Features2 />
            </div>

            <div id="text5" ref={text5Ref}>
                <Text5 isVisible={visibleSections.text5} />
            </div>
            <div id="footer">
                <Footer />
            </div>
        </div>
    );
}


function App() {
    return (
        <div className="relative min-h-screen">
            <Background />
            <Router>
                <div className="relative z-10">
                    <Routes>
                        <Route path="/" element={<MainContent />} />
                        <Route path="/terms" element={<TermsAndConditions />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/blog/*" element={<BlogPost />} />
                        <Route path="/generatesitemap" element={<Sitemap />} /> 
                        <Route path="/awh" element={<AdminDashboard />} />
                    </Routes>
                </div>
            </Router>
        </div>
    );
}


export default App;