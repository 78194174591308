import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';


const AdminDashboard = () => {
    const [csrfToken, setCsrfToken] = useState('');
    const [promotionalEmailInput, setPromotionalEmailInput] = useState('');
    const [showDashboard, setShowDashboard] = useState(false);
    const [dashboardData, setDashboardData] = useState({
        totalDownloads: 'Loading...',
        totalTrials: 'Loading...',
        activeTrials: 'Loading...',
        totalLicenses: 'Loading...'
    });

    const chartRefs = {
        downloadsChart: useRef(null),
        trialsChart: useRef(null),
        licensesChart: useRef(null)
    };

    const chartInstances = useRef({});

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await fetch('/api/csrf-token', {
                    credentials: 'include'
                });
                const data = await response.json();
                setCsrfToken(data.csrfToken);
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };

        fetchCsrfToken();
    }, []);

    useEffect(() => {
        if (showDashboard) {
            fetchDashboardData();
        }
    }, [showDashboard]);




    const login = async (event) => {
        event.preventDefault();
        const username = event.target.username.value;
        const password = event.target.password.value;

        if (!username.trim() || password.length < 4) {
            alert('Invalid input. Username is required and password must be at least 4 characters long.');
            return;
        }

        try {
            const response = await fetch('/api/admin/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include'
            });
            const data = await response.json();
            if (data.auth) {
                setShowDashboard(true);
            } else {
                alert('Invalid credentials');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred during login. Please try again.');
        }
    };


    const logout = async () => {
        try {
            const response = await fetch('/api/admin/logout', {
                method: 'POST',
                headers: {
                    'CSRF-Token': csrfToken,
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });

            if (response.ok) {
                setShowDashboard(false);
            } else {
                throw new Error('Logout failed');
            }
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleUnauthorized = () => {
        console.error('Authentication failed. Redirecting to login.');
        setShowDashboard(false);
    };

    const fetchDashboardData = () => {
        fetchData('/total-downloads', 'totalDownloads');
        fetchData('/total-trials', 'totalTrials', null, updateTrialsDisplay);
        fetchData('/active-trials', 'activeTrials', null, updateTrialsDisplay);
        fetchData('/total-licenses', 'totalLicenses');
        fetchData('/daily-downloads', null, 'downloadsChart');
        fetchData('/daily-trials', null, 'trialsChart');
        fetchData('/daily-licenses', null, 'licensesChart');
    };

    const fetchData = (url, dataKey, chartId = null, callback = null) => {
        fetch('/api/admin' + url, {
            headers: {
                'CSRF-Token': csrfToken
            },
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error('Authentication failed');
                    }
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (dataKey) {
                    setDashboardData(prev => ({ ...prev, [dataKey]: data[dataKey] }));
                }
                if (chartId) {
                    createChart(chartId, `Daily ${chartId.replace('Chart', '')}`, data);
                }
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                if (error.message === 'Authentication failed') {
                    handleUnauthorized();
                }
            });
    };

    const updateTrialsDisplay = () => {
        if (dashboardData.totalTrials !== 'Loading...' && dashboardData.activeTrials !== 'Loading...') {
            setDashboardData(prev => ({
                ...prev,
                totalTrials: `${prev.totalTrials}`,
                activeTrials: `Active: ${prev.activeTrials}`
            }));
        }
    };

    const createChart = (canvasId, label, data) => {
        const ctx = chartRefs[canvasId].current.getContext('2d');

        if (chartInstances.current[canvasId]) {
            chartInstances.current[canvasId].destroy();
        }

        chartInstances.current[canvasId] = new Chart(ctx, {
            type: 'line',
            data: {
                datasets: [{
                    label: label,
                    data: data.map(item => ({
                        x: new Date(item.date),
                        y: item[Object.keys(item)[1]]
                    })),
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'day'
                        },
                        adapters: {
                            date: {
                                locale: enUS
                            }
                        }
                    },
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });
    };

   const viewData = (url, title) => {
        fetch('/api/admin' + url, {
            headers: {
                'CSRF-Token': csrfToken
            },
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error('Authentication failed');
                    }
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                const formattedData = JSON.stringify(data, null, 2);
                const newWindow = window.open();
                newWindow.document.write(`
                <html>
                    <head>
                        <title>${title}</title>
                        <style>
                            body { 
                                font-family: Arial, sans-serif; 
                                padding: 20px; 
                                background-color: #000; 
                                color: #e0e0e0; 
                            }
                            h1 { color: #fffff; }
                            pre { 
                                background-color: #121212; 
                                padding: 15px; 
                                border-radius: 5px; 
                                white-space: pre-wrap; 
                                word-wrap: break-word;
                            }
                        </style>
                    </head>
                    <body>
                        <h1>${title}</h1>
                        <pre>${formattedData}</pre>
                    </body>
                </html>
            `);
            })
            .catch(error => {
                console.error('Error:', error);
                if (error.message === 'Authentication failed') {
                    handleUnauthorized();
                }
            });
    };

    const viewServerLogs = () => {
        fetch('/api/admin/server-logs', {
            headers: {
                'CSRF-Token': csrfToken
            },
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error('Authentication failed');
                    }
                    return response.json().then(err => {
                        throw new Error(err.error + (err.path ? ` (Path: ${err.path})` : ''));
                    });
                }
                return response.json();
            })
            .then(data => {
                const formattedLogs = data.logs.join('\n');
                const newWindow = window.open();
                newWindow.document.write(`
                <html>
                    <head>
                        <title>Server Logs</title>
                        <style>
                            body { 
                                font-family: monospace;
                                padding: 20px; 
                                background-color: #000; 
                                color: #e0e0e0; 
                            }
                            h1, h2 { color: #fffff; }
                            pre { 
                                background-color: #121212; 
                                padding: 15px; 
                                border-radius: 5px; 
                                white-space: pre-wrap; 
                                word-wrap: break-word;
                                max-height: 80vh;
                                overflow-y: auto;
                            }
                        </style>
                    </head>
                    <body>
                        <h1>Server Logs</h1>
                        <h2>File: ${data.fileName}</h2>
                        <pre>${formattedLogs}</pre>
                    </body>
                </html>
            `);
            })
            .catch(error => {
                console.error('Error:', error);
                if (error.message === 'Authentication failed') {
                    handleUnauthorized();
                } else {
                    alert('Error fetching server logs: ' + error.message);
                }
            });
    };

    const viewAllEmails = () => viewData('/all-emails', 'All Email Addresses');
    const viewAllTrials = () => viewData('/all-trials', 'All Trials');
    const viewAllLicenses = () => viewData('/all-licenses', 'All Licenses');



    const sendPromotionalLicense = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('/api/admin/send-promotional-license', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'CSRF-Token': csrfToken
                },
                body: JSON.stringify({ email: promotionalEmailInput }),
                credentials: 'include'
            });
            const data = await response.json();
            if (data.success) {
                alert('Promotional license key sent successfully!');
                setPromotionalEmailInput('');
            } else {
                alert('Failed to send promotional license key. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred while sending the promotional license key. Please try again.');
        }
    };


    return (
        <div className="container mx-auto px-4 py-8">
            {!showDashboard ? (
                <div className="container max-w-md mx-auto p-6 bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg mb-4">
                    <h1 className="text-2xl font-bold text-white text-center mb-4">Admin Login</h1>
                    <form onSubmit={login} className="space-y-4">
                        <div>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                placeholder="Username"
                                required
                                className="w-full px-3 py-2 text-white bg-black bg-opacity-50 rounded border border-gray-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 outline-none"
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Password"
                                required
                                className="w-full px-3 py-2 text-white bg-black bg-opacity-50 rounded border border-gray-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 outline-none"
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-black text-white flex items-center justify-center space-x-2 px-4 py-2 rounded-lg border-2 border-gray-500 hover:bg-opacity-80 transition duration-300"
                        >
                            <span>Login</span>
                        </button>
                    </form>
                </div>
            ) : (
                <div id="dashboard" className="space-y-6">
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl font-bold text-white">Admin Dashboard</h1>
                        <button
                            onClick={logout}
                            className="bg-black text-white px-4 py-2 rounded-lg border-2 border-gray-500 hover:bg-opacity-80 transition duration-300"
                        >
                            Logout
                        </button>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-4">
                            <h2 className="text-xl font-bold text-white mb-2">Downloads</h2>
                            <div className="chart-container h-64">
                                <canvas ref={chartRefs.downloadsChart}></canvas>
                            </div>
                        </div>
                        <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-4">
                            <h2 className="text-xl font-bold text-white mb-2">Trials</h2>
                            <div className="chart-container h-64">
                                <canvas ref={chartRefs.trialsChart}></canvas>
                            </div>
                        </div>
                        <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-4">
                            <h2 className="text-xl font-bold text-white mb-2">Licenses</h2>
                            <div className="chart-container h-64">
                                <canvas ref={chartRefs.licensesChart}></canvas>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-4">
                            <h2 className="text-xl font-bold text-white mb-2">Total Downloads</h2>
                            <p className="text-3xl font-bold text-white mb-4">{dashboardData.totalDownloads}</p>
                            <button
                                onClick={viewAllEmails}
                                className="w-full bg-black text-white px-4 py-2 rounded-lg border-2 border-gray-500 hover:bg-opacity-80 transition duration-300"
                            >
                                View All Emails
                            </button>
                        </div>
                            <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-4">
                                <h2 className="text-xl font-bold text-white mb-2">Total Trials</h2>
                              <p className="text-3xl font-bold text-white mb-4">{dashboardData.totalTrials}({dashboardData.activeTrials})</p>
                                <p className="text-white mb-4"></p>
                                <button
                                    onClick={viewAllTrials}
                                    className="w-full bg-black text-white px-4 py-2 rounded-lg border-2 border-gray-500 hover:bg-opacity-80 transition duration-300"
                                >
                                    View All Trials
                                </button>
                            </div>
                        <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-4">
                            <h2 className="text-xl font-bold text-white mb-2">Total Licenses</h2>
                            <p className="text-3xl font-bold text-white mb-4">{dashboardData.totalLicenses}</p>
                            <button
                                onClick={viewAllLicenses}
                                className="w-full bg-black text-white px-4 py-2 rounded-lg border-2 border-gray-500 hover:bg-opacity-80 transition duration-300"
                            >
                                View All Licenses
                            </button>
                        </div>
                        <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-4">
                            <h2 className="text-xl font-bold text-white mb-2">Server Logs</h2>
                            <button
                                onClick={viewServerLogs}
                                className="w-full bg-black text-white px-4 py-2 rounded-lg border-2 border-gray-500 hover:bg-opacity-80 transition duration-300"
                            >
                                View Server Logs
                            </button>
                            </div>


                            <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-4">
                                <h2 className="text-xl font-bold text-white mb-2">Promotional License Key</h2>
                                <form onSubmit={sendPromotionalLicense} className="flex space-x-2">
                                    <input
                                        type="email"
                                        value={promotionalEmailInput}
                                        onChange={(e) => setPromotionalEmailInput(e.target.value)}
                                        placeholder="Enter email"
                                        required
                                        className="flex-grow px-3 py-2 text-white bg-black bg-opacity-50 rounded border border-gray-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 outline-none"
                                    />
                                    <button
                                        type="submit"
                                        className="bg-black text-white px-4 py-2 rounded-lg border-2 border-gray-500 hover:bg-opacity-80 transition duration-300"
                                    >
                                        Send
                                    </button>
                                </form>
                            </div>

                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminDashboard;