import { useEffect, useRef } from 'react';

export function usePreloadVideo(src) {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.src = src;
            videoRef.current.load();
        }
    }, [src]);

    return videoRef;
}