import React, { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { TextGenerateEffect } from "./ui/text-generate-effect.tsx";
import { usePreloadVideo } from './usePreloadVideo'; 

const ARROW_SYMBOL = String.fromCodePoint(0x2022);  // Unicode for bullet point

const words1 = "Limit Execution";
const words2 = " 'pre-plan trades, set and forget' ";

function Text3({ isVisible, inView }) {
    const [key, setKey] = useState(0);

    useEffect(() => {
        if (inView) {
            setKey(prevKey => prevKey + 1);
        }
    }, [inView]);

    return (
        <div className="flex flex-col justify-center items-center relative z-10">
            {isVisible && (
                <div className="text-white text-shadow-lg">
                    <TextGenerateEffect key={`text1-${key}`} words={words1} />
                    <TextGenerateEffect key={`text2-${key}`} words={words2} />
                </div>
            )}
        </div>
    );
}

export default function CombinedFeatures4() {
    const [activeSection, setActiveSection] = useState(0);

    const videoRef = usePreloadVideo('/videos/limit.mp4');

    const [ref1, inView1] = useInView({ threshold: 0.8 });
    const [ref2, inView2] = useInView({ threshold: 0.8 });
    const [ref3, inView3] = useInView({ threshold: 0.8 });

    useEffect(() => {
        if (inView1) setActiveSection(0);
        else if (inView2) setActiveSection(1);
        else if (inView3) setActiveSection(2);
    }, [inView1, inView2, inView3]);

    useEffect(() => {
        let timeoutId;
        if (videoRef.current) {
            if (activeSection === 1) {
                timeoutId = setTimeout(() => {
                    videoRef.current.currentTime = 0;
                    videoRef.current.play().catch(e => console.log("Video play failed:", e));
                }, 500);
            } else {
                videoRef.current.pause();
            }
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [activeSection]);

    const sections = [
        {
            type: 'title',
            content: <Text3 isVisible={true} inView={inView1} />
        },
        {
            type: 'video',
            content: {
                src: "/videos/limit.mp4",
            }
        },
        {
            type: 'text',
            content: [
                ["Press TAB to enter Limit Mode.", "You can now move freely the Entry Line.", "Click to set the limit entry, and click again to set limit stoploss."],
                ["If the BE and TP settings based on RR input are active, they will be placed on the chart.", "After the position will be filled, the BE and TP levels will be active.", "You can pre-plan trades completely, no need to spend unnecessary time on charts."]
            ]
        }
    ];

    return (
        <div className="min-h-screen text-white p-4 sm:p-8 flex flex-col items-center justify-center relative z-10 mt-24 sm:mt-32 md:mt-40">
            <div className="w-full max-w-6xl">
                <div className="flex flex-col space-y-8 sm:space-y-20">
                    {sections.map((section, index) => (
                        <div
                            key={index}
                            ref={[ref1, ref2, ref3][index]}
                            className={`transition-all duration-500 ${activeSection === index
                                    ? 'opacity-100 scale-105 sm:scale-120'
                                    : 'opacity-30 scale-100'
                                } ${index === 1 ? '-mt-4 sm:mt-0' : ''} ${index === 2 ? '-mt-4 sm:mt-0' : ''}`}
                        >
                            {section.type === 'title' && (
                                <div className="flex items-center justify-center mb-2 sm:mb-6 mt-8 sm:mt-16">
                                    {section.content}
                                </div>
                            )}
                            {section.type === 'video' && (
                                <div className="flex justify-center items-center">
                                    <video
                                        ref={videoRef}
                                       
                                        className="max-w-full max-h-[60vh] sm:max-h-[70vh] object-contain rounded-2xl"
                                        muted
                                        playsInline
                                       
                                    />
                                </div>
                            )}
                            {section.type === 'text' && (
                                <div className="flex flex-col md:flex-row justify-center items-start md:items-stretch">
                                    {section.content.map((column, columnIndex) => (
                                        <div key={columnIndex} className="flex-1 md:w-1/2 p-4 flex flex-col">
                                            <div className="bg-black bg-opacity-50 p-4 sm:p-6 rounded-xl flex-grow">
                                                <ul className="list-disc list-outside text-xs sm:text-sm md:text-base lg:text-lg text-left space-y-0.5 sm:space-y-0.5 ml-5">
                                                    {column.map((item, itemIndex) => (
                                                        <li key={itemIndex} className="pl-0.1">{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}