import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default function Features2() {
    const [activeSection, setActiveSection] = useState(0);

    const [ref1, inView1] = useInView({ threshold: 1 });
    const [ref2, inView2] = useInView({ threshold: 1 });
    const [ref3, inView3] = useInView({ threshold: 1 });
   

    useEffect(() => {
        if (inView1) setActiveSection(0);
        else if (inView2) setActiveSection(1);
        else if (inView3) setActiveSection(2);
       
    }, [inView1, inView2, inView3]);

    const features = [
        {
            img: "/images/icmarketslogo.png",
            alt: "IC Markets",
            link: "https://www.icmarkets.com/",
            items: [
                "Open an account with any broker supporting MT5",
                "We recommend IC Markets being industry leader",
                "Set up a demo account on MT5 (or live)"
               
                
            ]
        },
        {
            img: "/images/duplikiumlogo.png",
            alt: "Dulikium",
            link: "https://www.trade-copier.com/",
            items: [
                "Open an account with Duplikium",
                "Well established trade copier service Swiss based",
                "Set up a free or paid account",
                "Link your MT5 as master and any MT4/cTrader accounts as slaves",
                "Adjust risk settings and you're good to go",
                "You only need to input accounts credentials and execution is done in cloud"
            ]
        },
        {
            img: "/images/mt5weblogo.png",
            alt: "MetaTrader 5",
            link: "https://www.metatrader5.com/",
            items: [
                "Download MetaTrader 5 for your Operating System",
                "Latest version is supported on Windows, macOS and Linux",
                "Download Flow Execution",
                "Follow the Installation Tutorial",
                "Login with your IC Markets MT5 demo account",
                "You are ready to Flow with the price"
            ]
        }

    ];

    return (
        <div className="min-h-screen text-white mb-30 mt-40 p-2 sm:p-8 flex flex-col items-center justify-center relative z-10">
            <div className="w-full max-w-8xl">
                <div className="mb-12">
                    <h2 className="text-4xl sm:text-6xl font-bold mb-10 sm:mb-20 text-center">For MetaTrader 4 & cTrader</h2>
                    <div className="flex flex-col space-y-16 sm:space-y-24">
                        {features.map((feature, index) => (
                            <div
                                key={index}
                                ref={[ref1, ref2, ref3][index]}
                                className={`flex flex-col items-center justify-center transition-all duration-500 ${activeSection === index ? 'opacity-100' : 'opacity-10'}`}
                            >
                                <div className={`w-full max-w-xs sm:max-w-sm mb-2 md:mb-6 transition-all duration-500 p-3 sm:p-5 md:p-0 ${activeSection === index ? 'scale-active' : ''}`}>
                                    <a href={feature.link} target="_blank" rel="noopener noreferrer">
                                        <img
                                            src={feature.img}
                                            alt={feature.alt}
                                            className="w-auto h-auto rounded-2xl mx-auto"
                                        />
                                    </a>
                                </div>
                                <div
                                    className={`w-full max-w-xs sm:max-w-lg transition-all duration-500 ${activeSection === index ? 'scale-active' : ''}`}
                                >
                                    <div className="relative flex items-center justify-center">
                                        <div className="relative -left-0 sm:-left-10 md:-left-4 text-3xl sm:text-4xl font-bold text-gray-400 mr-0 sm:mr-0">
                                            {index + 1}
                                        </div>
                                        <div className="bg-black bg-opacity-50 p-3 sm:p-6 rounded-xl ml-2 sm:ml-0 w-full md:w-auto md:inline-block">
                                            <ul className="list-none text-xs sm:text-xs text-left sm:text-left md:whitespace-nowrap">
                                                {feature.items.map((item, itemIndex) => (
                                                    <li key={itemIndex} className="mb-1 sm:mb-0">{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className=""></div>
        </div>
    );
}