import React from "react";
import { ContainerScroll } from "./ui/container-scroll-animation.tsx";



export default function Meta() {
    return (
      

                    <ContainerScroll
                        titleComponent={
                            <>
                                <h1 className="text-4xl font-semibold text-white dark:text-white">
                                    Unleash the power of <br />
                                    <span className="text-4xl md:text-[6rem] font-bold mt-1 leading-none">
                                        FLOW EXECUTION
                                    </span>
                                </h1>
                            </>
                        }
                    >
                        <img
                            src={`/images/mt54.png`}
                            alt="hero"
                            height={647}
                            width={1236}
                            className="mx-auto rounded-2xl object-cover h-full object-right-top"
                            draggable={false}
                        />
        </ContainerScroll>


    );
}