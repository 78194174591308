import React from "react";
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";
import { HoverBorderGradient } from "./ui/hover-border-gradient.tsx";
import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
    return (


         <>
            <Helmet>
                <title>Privacy Policy | Flow Execution - Protecting Your Trading Data</title>
                <meta name="description" content="Learn how Flow Execution safeguards your trading information. Our privacy policy outlines our commitment to data security and transparency in forex and CFD trading." />
                <meta name="keywords" content="Flow Execution, privacy policy, data protection, forex trading, CFD trading, MetaTrader 5" />
                <meta property="og:title" content="Flow Execution Privacy Policy - Secure Trading Data Management" />
                <meta property="og:description" content="Discover how Flow Execution protects your trading data. Our comprehensive privacy policy ensures your information is secure while using our MetaTrader 5 tools." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.flowexecution.com/privacy-policy" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Flow Execution Privacy Policy - Your Data, Our Priority" />
                <meta name="twitter:description" content="Flow Execution's privacy policy: Learn how we protect your trading data and maintain transparency in our MetaTrader 5 tools for forex and CFD trading." />
            </Helmet>


        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="px-7 sm:px-0 py-16"
        >
            <div className="max-w-4xl mx-auto">
                <h1 className="text-2xl font-bold text-white mb-8 text-center">PRIVACY POLICY</h1>
                <div className="relative z-10 bg-zinc-900 bg-opacity-40 rounded-lg shadow-lg overflow-hidden border border-zinc-700">
                    <div className="p-8 space-y-6 text-white backdrop-blur-sm">
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">1. Information Collection</h2>
                            <p>We collect certain information necessary for license validation, product improvement, and customer support. This may include, but is not limited to, license keys and system information. We do not collect personal trading data, account information, or other sensitive financial data.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">2. Use of Information</h2>
                            <p>The information we collect is used to validate licenses, provide customer support, improve our products, and ensure the proper functioning of our EA. We may use aggregated, anonymized data for statistical analysis and product development purposes.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">3. Information Sharing</h2>
                            <p>We do not sell, trade, or rent your personal information to third parties. We may share generic aggregated demographic information not linked to any personal identification information with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
                            <p>We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">5. Data Retention</h2>
                            <p>We will retain your information for as long as your account is active or as needed to provide you services. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">6. Your Rights</h2>
                            <p>Depending on your location, you may have certain rights regarding your personal information, such as the right to access, correct, or delete the data we hold about you. If you wish to exercise any of these rights, please contact us using the information provided below.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">7. Cookies and Similar Technologies</h2>
                            <p>Our EA and website may use "cookies" or similar technologies to enhance user experience and gather information about usage patterns. You can choose to disable cookies through your individual browser options, although this may affect your ability to use certain features of our EA or website.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">8. Changes to This Policy</h2>
                            <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the effective date at the bottom of this policy. You are advised to review this policy periodically for any changes.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">9. Contact Us</h2>
                            <p>If you have any questions about this Privacy Policy or our data practices, please contact us at legal@flowexecutiom.com.</p>
                        </section>
                        <p className="mt-8 text-sm">Effective Date: 24.07.2024</p>
                    </div>
                </div>


                <div className="mt-8 flex justify-center">
                    <Link to="/">
                        <HoverBorderGradient
                            containerClassName="rounded-lg border-2 border-gray-500"
                            as="button"
                                className="bg-black text-white flex items-center text-xs space-x-2 px-4 py-2"
                        >
                            <ArrowLeft className="h-4 w-4" />
                            <span>BACK</span>
                        </HoverBorderGradient>
                    </Link>
                </div>



            </div>
            </motion.div>
        </>
    );
}