import React, { useRef, useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { cn } from "../../lib/utils.ts";

export const HoverEffect = ({ items, className }) => {
    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end start"]
    });

    return (
        <div
            ref={containerRef}
            className={cn(
                "grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8 py-10",
                className
            )}
        >
            {items.map((item, idx) => (
                <CardWrapper key={idx} index={idx} scrollYProgress={scrollYProgress} totalItems={items.length}>
                    <Card>
                        <CardTitle icon={item.icon}>{item.title}</CardTitle>
                        <CardDescription>{item.description}</CardDescription>
                    </Card>
                </CardWrapper>
            ))}
        </div>
    );
};

const CardWrapper = ({ children, index, scrollYProgress, totalItems }) => {
    const [layout, setLayout] = useState('mobile');

    useEffect(() => {
        const handleResize = () => {
            setLayout(window.innerWidth >= 1024 ? 'desktop' : 'mobile');
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getDirection = () => {
        if (layout === 'mobile') {
            return index % 2 === 0 ? -1 : 1;
        } else {
            const column = index % 3;
            return column === 0 ? -1 : column === 2 ? 1 : 0;
        }
    };

    const direction = getDirection();

    const x = useTransform(
        scrollYProgress,
        [0, 0.5, 1],
        layout === 'desktop'
            ? [direction * 200, 0, direction * -40]  // Increased initial and final distances for desktop
            : [direction * 100, 0, direction * -20]  // Keep mobile values the same
    );

    const opacity = useTransform(
        scrollYProgress,
        [0, 0.2, 0.8, 1],
        [0, 1, 1, 0]
    );

    return (
        <motion.div
            style={{ x, opacity }}
            className="relative block p-2 h-full w-full"
        >
            {children}
        </motion.div>
    );
};

export const Card = ({ className, children }) => {
    return (
        <div
            className={cn(
                "rounded-2xl h-full w-full p-4 overflow-hidden bg-black/50 border border-transparent dark:border-white/[0.2] group-hover:border-slate-700 relative z-20",
                className
            )}
        >
            <div className="relative z-50">
                <div className="p-4">
                    {children}
                </div>
            </div>
        </div>
    );
};

export const CardTitle = ({ className, children, icon }) => {
    return (
        <div className={cn(
            "flex flex-col sm:flex-row items-center sm:items-start sm:space-x-3",
            "text-zinc-100 font-bold tracking-wide",
            "mt-2 sm:mt-4 md:mt-6 lg:mt-8",
            className
        )}>
            {icon && (
                <div className="flex-shrink-0 text-gray-500 mb-2 sm:mb-0">
                    {React.cloneElement(icon, {
                        className: "h-6 w-6 sm:h- sm:w-6 md:h-6 md:w-6 lg:h-7 lg:w-7 mt-1"
                    })}
                </div>
            )}
            <h4 className="text-center sm:text-left text-lg sm:text-xl md:text-2xl lg:text-2xl">
                {children}
            </h4>
        </div>
    );
};

export const CardDescription = ({ className, children }) => {
    return (
        <div className="flex flex-col justify-center sm:block h-full">
            <p
                className={cn(
                    "mt-6 sm:mt-4 md:mt-6 lg:mt-8",
                    "text-zinc-400 tracking-wide leading-relaxed",
                    "text-xs sm:text-sm md:text-base lg:text-lg",
                    "text-center sm:text-left",
                    className
                )}
            >
                {Array.isArray(children)
                    ? children.map((line, index) => <div key={index}>{line}</div>)
                    : children}
            </p>
        </div>
    );
};