import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { Instagram, FileText, Shield, Mail, Newspaper } from 'lucide-react';

export default function Footer() {
    const footerItems = [
        { icon: <Instagram size={24} />, title: "Instagram", href: "https://www.instagram.com/flow.execution" },
        { icon: <FileText size={24} />, title: "Terms & Conditions", href: "/terms" },
        { icon: <Shield size={24} />, title: "Privacy Policy", href: "/privacy" },
        { icon: <Newspaper size={24} />, title: "Blog", href: "/blog" },
        { icon: <Mail size={24} />, title: "Contact", href: "/contact" }
       
    ];

    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end start"]
    });

    const opacity = useTransform(
        scrollYProgress,
        [0, 0.2, 0.8, 1],
        [0, 1, 1, 0]
    );

    const y = useTransform(
        scrollYProgress,
        [0, 0.2, 0.8, 1],
        [50, 0, 0, -50]
    );

    return (
        <motion.footer
            ref={containerRef}
            style={{ opacity, y }}
            className="px-4 sm:px-7 mt-16 mb-10"
        >
            <div className="relative z-10 bg-zinc-900 bg-opacity-10 max-w-4xl mx-auto rounded-lg shadow-lg overflow-hidden border border-zinc-700">
                <div className="flex flex-nowrap justify-around items-center px-2 sm:px-8 py-2 sm:py-8 backdrop-blur-sm">
                    {footerItems.map((item, index) => (
                        <a
                            key={index}
                            href={item.href}
                            className="flex flex-col items-center group"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className="text-white group-hover:text-indigo-400 transition-colors duration-200">
                                {React.cloneElement(item.icon, {
                                    size: 14,
                                    className: 'w-3 h-3 sm:w-6 sm:h-6'
                                })}
                            </div>
                            <span className="text-white text-[10px] sm:text-sm font-medium mt-1 sm:mt-2 group-hover:text-indigo-400 transition-colors duration-200">
                                {item.title}
                            </span>
                        </a>
                    ))}
                </div>
            </div>
        </motion.footer>
    );
}
