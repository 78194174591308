import React from "react";
import { TextGenerateEffect } from "./ui/text-generate-effect.tsx";

const line1 = `How to set everything up.`;
const line2 = `Almost done.`;

export function Text4({ isVisible }) {
    return (
        <div className="flex flex-col justify-center items-center min-h-screen relative z-10">
            {isVisible && (
                <div className="text-white text-shadow-lg">
                    <TextGenerateEffect words={line1} className="text-center mb-4" />
                    <TextGenerateEffect words={line2} className="text-center" />
                </div>
            )}
        </div>
    );
}