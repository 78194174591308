import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Sitemap = () => {
    const [sitemapContent, setSitemapContent] = useState('');
    const navigate = useNavigate();

    const createUrlFriendlyTitle = (title) => {
        return title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
    };

    useEffect(() => {
        const generateSitemap = () => {
            const baseUrl = 'https://flowexecution.com'; // Replace with your actual domain
            const currentDate = new Date().toISOString().split('T')[0];
            const staticRoutes = [
                '/',
                '/terms',
                '/privacy',
                '/contact',
                '/blog',
            ];
            const blogPosts = [
                { title: "IC Markets: A Comprehensive Review for Forex and CFD Traders", date: "2024-07-15" },
                { title: "MetaTrader 5: The Ultimate Trading Platform for Modern Traders", date: "2024-07-10" },
                { title: "FTMO: Revolutionizing Prop Trading for Aspiring Traders", date: "2024-07-05" },
                { title: "Flow Execution: Revolutionizing Trading on MetaTrader 5", date: "2024-06-30" },
                { title: "Using Flow Execution with MetaTrader 4 and cTrader: A Comprehensive Guide", date: "2024-06-25" }
            ];
            let xml = '<?xml version="1.0" encoding="UTF-8"?>\n';
            xml += '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n';
            staticRoutes.forEach(route => {
                xml += '  <url>\n';
                xml += `    <loc>${baseUrl}${route}</loc>\n`;
                xml += `    <lastmod>${currentDate}</lastmod>\n`;
                xml += '  </url>\n';
            });
            blogPosts.forEach(post => {
                xml += '  <url>\n';
                xml += `    <loc>${baseUrl}/blog/${createUrlFriendlyTitle(post.title)}</loc>\n`;
                xml += `    <lastmod>${post.date}</lastmod>\n`;
                xml += '  </url>\n';
            });
            xml += '</urlset>';
            setSitemapContent(xml);
        };
        generateSitemap();
    }, []);

    useEffect(() => {
        if (sitemapContent) {
            const blob = new Blob([sitemapContent], { type: 'application/xml' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'sitemap.xml';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            navigate('/'); // Redirect to home page after download starts
        }
    }, [sitemapContent, navigate]);

    return null;
};

export default Sitemap;