import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

export default function Logos() {
    const logos = [
        { src: "/images/metatrader5.png", alt: "MetaTrader5 logo", title: "MetaTrader 5" },
        { src: "/images/windows.png", alt: "Windows logo", title: "Windows" },
        { src: "/images/apple.png", alt: "Apple logo", title: "macOS" },
        { src: "/images/linux.png", alt: "Linux logo", title: "Linux" }
    ];

    const containerRef = useRef(null);
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end start"]
    });

    const opacity = useTransform(
        scrollYProgress,
        [0, 0.2, 0.8, 1],
        [0, 1, 1, 0]
    );

    const y = useTransform(
        scrollYProgress,
        [0, 0.2, 0.8, 1],
        [50, 0, 0, -50]
    );

    return (
        <motion.div
            ref={containerRef}
            style={{ opacity, y }}
            className="px-7 sm:px-0"
        >
            <h4 className="relative text-center text-zinc-400 text-sm sm:text-base mb-2 sm:mb-4">
                Supported by
            </h4>
            <div className="relative z-10 bg-zinc-900 bg-opacity-40 max-w-4xl mx-auto rounded-lg shadow-lg overflow-hidden my-8 border border-zinc-700">
                
                <div className="flex justify-around items-center px-8 py-8 space-x-8 backdrop-blur-sm">
                    {logos.map((logo, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <img
                                src={logo.src}
                                alt={logo.alt}
                                className="w-8 h-8 sm:w-14 sm:h-14 object-contain filter drop-shadow-lg mb-2"
                            />
                            <span className="text-white text-[10px] sm:text-sm font-medium whitespace-nowrap">{logo.title}</span>
                        </div>
                    ))}
                </div>
            </div>
        </motion.div>
    );
}