import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default function Features1() {
    const [activeSection, setActiveSection] = useState(0);

    const [ref1, inView1] = useInView({ threshold: 1 });
    const [ref2, inView2] = useInView({ threshold: 1 });
    const [ref3, inView3] = useInView({ threshold: 1 });
    const [ref4, inView4] = useInView({ threshold: 1 });
    const [ref5, inView5] = useInView({ threshold: 1 });
    const [ref6, inView6] = useInView({ threshold: 1 });

    useEffect(() => {
        if (inView1) setActiveSection(0);
        else if (inView2) setActiveSection(1);
        else if (inView3) setActiveSection(2);
        else if (inView4) setActiveSection(3);
        else if (inView5) setActiveSection(4);
        else if (inView6) setActiveSection(5);
    }, [inView1, inView2, inView3, inView4, inView5, inView6]);

    const features = [
        {
            img: "/images/s1.png",
            alt: "Trading Assistant Settings",
            items: [
                "Within your MetaTrader 5",
                "Navigate to 'Tools' dropdown menu",
                "Select 'Options'",
                "A new settings window will open"
            ]
        },
        {
            img: "/images/s2.png",
            alt: "Trading Assistant Settings",
            items: [
                "Within the new window, navigate to tab 'Expert Advisors'",
                "Check 'Allow WebRequest for listed URL'",
                "Click add new URL and insert https://flowexecution.com",
                "Click Ok and you are good to go",
                "This step is necessary to create Trials and Verify License Keys"
            ]
        },
        {
            img: "/images/s.png",
            alt: "Trading Assistant Settings",
            items: [
                "Within your MetaTrader 5",
                "Navigate to 'File' dropdown menu",
                "Select 'Open Data Folder'",
                "The folder where MetaTrader is installed will open"
            ]
        },
        {
            img: "/images/folders.png",
            alt: "Trading Assistant Settings",
            items: [
                "Navigate through folders until 'Advisors'",
                "(MQL5 > Experts > Advisors)",
                "Place here the FlowExecution.ex5 file",
                "Close the folder and get back to MT5"
               
            ]
        },
        {
            img: "/images/s0.png",
            alt: "Trading Assistant Features",
            items: [
                "Look in the Navigator section, under Accounts",
                "You will see the 'Advisors' folder",
                "Right click and select 'Refresh'",
                "FlowExecution will appear",
                "Drag and drop it to the chart or double-click on it",
                "(It can be used on multiple charts with individual settings)",
                "Your free 5 day trial will start automatically"
            ]
        },
        
        {
            img: "/images/s3.png",
            alt: "Trading Assistant Features",
            items: [
                "In order to add a License Key",
                "When opening the EA select tab 'Inputs'",
                "If the EA is already open, click on 'FlowExecution in top-right corner",
                "Insert the key in value field",
                "Click Ok and you are good to go"
            ]
        }
        
    ];

    return (
        <div className="min-h-screen text-white p-2 sm:p-8 flex flex-col items-center justify-center relative z-10">
            <div className="w-full max-w-7xl">
                <div className="mb-12">
                    <h2 className="text-4xl sm:text-6xl font-bold mb-10 sm:mb-20 text-center">Installation Tutorial</h2>
                    <div className="flex flex-col space-y-16 sm:space-y-24">
                        {features.map((feature, index) => (
                            <div
                                key={index}
                                ref={[ref1, ref2, ref3, ref4, ref5, ref6][index]}
                                className={`flex flex-col md:flex-row-reverse items-center justify-center transition-all duration-500 ${activeSection === index ? 'opacity-100' : 'opacity-10'}`}
                            >
                                <div className={`w-full max-w-xs sm:max-w-sm mb-2 md:mb-0 md:ml-6 transition-all duration-500 p-3 sm:p-5 md:p-0 ${activeSection === index ? 'scale-active' : ''}`}>
                                    <img
                                        src={feature.img}
                                        alt={feature.alt}
                                        className="w-auto h-auto rounded-2xl mx-auto"
                                    />
                                </div>
                                <div
                                    className={`w-full max-w-xs sm:max-w-lg transition-all duration-500 ${activeSection === index ? 'scale-active margin-active md:ml-1 md:pr-2' : ''}`}
                                >
                                    <div className="relative flex flex-col items-start md:items-center">
                                        <div className="absolute -left-4 top-1/2 md:static md:mb-2 text-3xl sm:text-4xl font-bold text-gray-400 mr-1 sm:mr-0 md:transform md:-translate-y-0 md:translate-x-0 transform -translate-y-1/2">
                                            {index + 1}
                                        </div>
                                        <div className="bg-black bg-opacity-50 p-3 sm:p-6 rounded-xl ml-4 md:ml-0 w-full md:w-auto md:inline-block">
                                            <ul className="list-none text-xs sm:text-xs text-left md:whitespace-nowrap">
                                                {feature.items.map((item, itemIndex) => (
                                                    <li key={itemIndex} className="mb-1 sm:mb-0">{item}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}