import React, { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { TextGenerateEffect } from "./ui/text-generate-effect.tsx";
import { HoverBorderGradient } from "./ui/hover-border-gradient.tsx";
import { ArrowRight } from "lucide-react";
import { Zap, Brain, Briefcase, DollarSign, Target, BarChart3, ShieldCheck, Crosshair, MousePointer, Info, Clock } from 'lucide-react';
import { FaClone } from 'react-icons/fa';
import { usePreloadVideo } from './usePreloadVideo'; 


const words1 = "Designed for you to win.";
const words2 = "One click, job done.";

function Text3({ isVisible, inView }) {
    const [key, setKey] = useState(0);

    useEffect(() => {
        if (inView) {
            setKey(prevKey => prevKey + 1);
        }
    }, [inView]);

    return (
        <div className="flex flex-col justify-center items-center relative z-10">
            {isVisible && (
                <div className="text-white text-shadow-lg">
                    <TextGenerateEffect key={`text1-${key}`} words={words1} />
                    <TextGenerateEffect key={`text2-${key}`} words={words2} />
                </div>
            )}
        </div>
    );
}






export default function CombinedFeatures() {
    const [activeSection, setActiveSection] = useState(0);
    const [trialEmail, setTrialEmail] = useState('');
    const [downloadSuccess, setDownloadSuccess] = useState(false);
    const [error, setError] = useState(null);

    const videoRef = usePreloadVideo('/videos/click.mp4');

    const isDesktop = useMediaQuery({ minWidth: 768 });
    const threshold = isDesktop ? 0.8 : 0.5;

    const [ref1, inView1] = useInView({
        threshold,
        triggerOnce: false
    });
    const [ref2, inView2] = useInView({
        threshold,
        triggerOnce: false
    });

    useEffect(() => {
        if (inView1) setActiveSection(0);
        else if (inView2) setActiveSection(1);
    }, [inView1, inView2]);

    useEffect(() => {
        let timeoutId;
        if (videoRef.current) {
            if (activeSection === 1) {
                timeoutId = setTimeout(() => {
                    videoRef.current.currentTime = 0;
                    videoRef.current.play().catch(e => console.log("Video play failed:", e));
                }, 500);
            } else {
                videoRef.current.pause();
            }
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [activeSection]);

    const handleTrialDownload = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/downloads', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: trialEmail }),
            });

            if (!response.ok) {
                throw new Error('Failed to process trial download');
            }

            // Handle file download
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'FlowExecution.ex5';
            document.body.appendChild(link);
            link.click();
            link.remove();

            setTrialEmail('');
            setDownloadSuccess(true);

            // Redirect to the features1 section
            setTimeout(() => {
                window.location.hash = 'features1';
                const element = document.getElementById('features1');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000);

        } catch (error) {
            console.error('Error processing trial download:', error);
            setError(error.message || 'An error occurred. Please try again.');
        }
    };



    return (
        <div className="min-h-screen text-white p-4 sm:p-8 flex flex-col items-center justify-center relative z-10 mt-24 sm:mt-32 md:mt-40">
            <div className="w-full max-w-7xl">
                <div className="flex flex-col space-y-10 sm:space-y-20">
                    <div
                        ref={ref1}
                        className={`transition-all duration-500 ${activeSection === 0 ? 'opacity-100 scale-105 sm:scale-120' : 'opacity-30 scale-100'}`}
                    >
                        <div className="flex items-center justify-center mb-6 mt-8 sm:mt-16">
                            <Text3 isVisible={true} inView={inView1} />
                        </div>
                    </div>

                    <div
                        ref={ref2}
                        className={`transition-all duration-500 ${activeSection === 1 ? 'opacity-100 scale-105 sm:scale-120' : 'opacity-30 scale-100'}`}
                    >
                        <div className="flex flex-col md:flex-row justify-between items-center md:items-stretch">
                            <div className="md:w-1/2 p-4 flex justify-center items-center">
                                <div className="w-full max-w-[500px] aspect-[3/4] bg-black rounded-2xl overflow-hidden">
                                    <video
                                        ref={videoRef}
                                       
                                        className="w-full h-full object-cover object-right-top"
                                        muted
                                        playsInline
                                        preload="metadata"
                                    />
                                </div>
                            </div>
                            <div className="md:w-1/2 p-4 flex flex-col md:justify-between">
                                <div className="bg-black bg-opacity-50 p-4 sm:p-6 rounded-xl">
                                    <ul className="list-none md:list-none text-xs sm:text-sm md:text-lg text-left space-y-0.5 sm:space-y-0.5 md:space-y-2 ml-5 md:ml-0">
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <Zap className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Tired of missed opportunities and emotional trading decisions?</span>
                                        </li>
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <Zap className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">No more rushed BEs or early TPs</span>
                                        </li>
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <Brain className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Zero emotions, you can fully focus on your strategy</span>
                                        </li>
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <Briefcase className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Professional-grade trading, execute and focus on the next</span>

                                        </li>
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <FaClone className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Built-in MT5 chart template, ready to trade in seconds</span>

                                        </li>
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <DollarSign className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Automatic currency risk conversion into lots based on distance</span>
                                        </li>
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <ShieldCheck className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Safe and sound, cannot execute trades without stoploss</span>
                                        </li>
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <Target className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Predefined BreakEven by distance in RR</span>
                                        </li>
                                        
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <BarChart3 className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Predefined TakeProfit levels in RR with optional partials</span>
                                        </li>
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <Crosshair className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Manually adjustable to your own level with 1 click</span>
                                        </li>
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <Info className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Live trade information on BE/TP lines</span>
                                        </li>
                                        <li className="flex items-start md:items-start pl-0.1 md:pl-0">
                                            <Clock className="w-4 h-4 md:w-6 md:h-6 mr-2 flex-shrink-0 text-indigo-400" />
                                            <span className="flex-grow">Set and forget</span>
                                        </li>

                                    </ul>


                                    <div className="mt-8 hidden md:block">
                                        <div className="bg-black bg-opacity-0 p-4 rounded-lg w-full">

                                            <form onSubmit={handleTrialDownload} className="flex space-x-2">
                                                <input
                                                    type="email"
                                                    value={trialEmail}
                                                    onChange={(e) => setTrialEmail(e.target.value)}
                                                    placeholder="Email"
                                                    required
                                                    className="w-60 px-3 py-2 text-sm text-white bg-black bg-opacity-50 rounded border border-gray-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 outline-none"
                                                />
                                                <HoverBorderGradient
                                                    containerClassName="rounded-lg border-2 border-gray-500"
                                                    as="button"
                                                    type="submit"
                                                    className="bg-black text-white flex items-center justify-center space-x-3 px-2.5 py-2.5 text-xs whitespace-nowrap"
                                                >
                                                    <ArrowRight className="h-4 w-4" />
                                                    <span>TRY IT 5 DAYS FOR FREE</span>
                                                </HoverBorderGradient>
                                            </form>
                                            {downloadSuccess && (
                                                <p className="text-green-500 mt-2 text-sm">
                                                    Download successful! Redirecting to installation instructions...
                                                </p>
                                            )}
                                            {error && (
                                                <p className="text-red-500 mt-2 text-sm">
                                                    {error}
                                                </p>
                                            )}
                                        </div>
                                    </div>


                                </div>

                              

                                <div className="mt-8 flex md:hidden justify-center">
                                    <a href="#payment">
                                        <HoverBorderGradient
                                            containerClassName="rounded-lg border-2 border-gray-500"
                                            as="button"
                                            className="bg-black text-white flex items-center space-x-2 px-4 py-2"
                                        >
                                            <ArrowRight className="h-4 w-4" />
                                            <span>Try for Free</span>
                                        </HoverBorderGradient>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}