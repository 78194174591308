import React from "react";
import { BackgroundGradientAnimation } from "./ui/background-gradient-animation.tsx";


export default function Background() {
    return (
        <div className="fixed inset-0 z-0">
            <BackgroundGradientAnimation>
                <div className="flex flex-col overflow-hidden">
                    <div className="absolute z-10 inset-0 flex items-center justify-center text-white font-bold px-4 pointer-events-none text-3xl text-center md:text-4xl lg:text-7xl">
                       
                    </div>
                </div>
            </BackgroundGradientAnimation>
        </div>
    );
}