import React from "react";
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";
import { HoverBorderGradient } from "./ui/hover-border-gradient.tsx";
import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";

export default function Contact() {
    return (


         <>
            <Helmet>
                <title>Contact Flow Execution | Support for MetaTrader 5 Trading Tools</title>
                <meta name="description" content="Get in touch with Flow Execution for support with our MetaTrader 5 trading tools. We're here to assist with technical issues, partnerships, and general inquiries." />
                <meta name="keywords" content="Flow Execution, contact, support, MetaTrader 5, trading tools, forex support, CFD support" />
                <meta property="og:title" content="Contact Flow Execution - Expert Support for Trading Tools" />
                <meta property="og:description" content="Need help with Flow Execution's MetaTrader 5 tools? Our London-based team is ready to assist with technical support, partnerships, and general inquiries." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.flowexecution.com/contact" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Reach Out to Flow Execution - Trading Tools Support" />
                <meta name="twitter:description" content="Contact Flow Execution for expert support with our MetaTrader 5 trading tools. Technical help, partnership inquiries, and general assistance available." />
            </Helmet>


        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="px-7 sm:px-0 py-16"
        >
            <div className="max-w-4xl mx-auto">
                <h1 className="text-2xl font-bold text-white mb-8 text-center">CONTACT US</h1>
                <div className="bg-zinc-900 bg-opacity-40 rounded-lg shadow-lg overflow-hidden border border-zinc-700">
                    <div className="p-8 space-y-6 text-white backdrop-blur-sm">
                        <section>
                            <h2 className="text-xl font-semibold mb-4">About</h2>
                            <p> London based enthusiastic IT team, developing software for traders based on their needs.</p>
                        </section>
                        <section>
                            <h3 className="text-xl font-semibold mb-2">Partnerships & Affiliates</h3>
                            <p><a href="mailto:contact@flowexecution.com" className="text-indigo-400 hover:text-indigo-300">business@flowexecution.com</a></p>
                        </section>
                        
                        <section>
                            <h3 className="text-xl font-semibold mb-2">Technical Support</h3>
                            <p><a href="mailto:contact@flowexecution.com" className="text-indigo-400 hover:text-indigo-300">technical@flowexecution.com</a></p>
                        </section>
                        <section>
                            <h3 className="text-xl font-semibold mb-2">General Contact</h3>
                            <p><a href="mailto:contact@flowexecution.com" className="text-indigo-400 hover:text-indigo-300">contact@flowexecution.com</a></p>
                        </section>
                    </div>
                </div>



                <div className="mt-8 flex justify-center">
                    <Link to="/">
                        <HoverBorderGradient
                            containerClassName="rounded-lg border-2 border-gray-500"
                            as="button"
                                className="bg-black text-white flex items-center text-xs space-x-2 px-4 py-2"
                        >
                            <ArrowLeft className="h-4 w-4" />
                            <span>BACK</span>
                        </HoverBorderGradient>
                    </Link>
                </div>



            </div>
            </motion.div>
        </>
    );
}