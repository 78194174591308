import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { HoverBorderGradient } from "./ui/hover-border-gradient.tsx";
import { CreditCard, Zap, Infinity, Monitor, Mail, Clock, Key } from 'lucide-react';


const stripePromise = loadStripe('pk_live_8T232jHjPPm48PMtLXdp9TVV00womS6N9v');
//const stripePromise = loadStripe('pk_test_51PUBALRqQ0rTXyXFoSPBrdwLR3fCE4SaUnbbL7aPcE0mXftjepy6PCaxqgaW9aVURgDydebKx4pAlrzfOa94GLWG00yZf0dThf');


const CheckoutForm = () => {
    const [email, setEmail] = useState('');
    const [trialEmail, setTrialEmail] = useState('');
    const [error, setError] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [downloadSuccess, setDownloadSuccess] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);

    const handleTrialDownload = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/downloads', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email: trialEmail }),
            });
            
            if (!response.ok) {
                throw new Error('Failed to process trial download');
            }
            
            // Handle file download
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'FlowExecution.ex5';
            document.body.appendChild(link);
            link.click();
            link.remove();
            
            setTrialEmail('');
            setDownloadSuccess(true);
            // Show success message to user


            // Redirect to the features1 section
            setTimeout(() => {
                window.location.hash = 'features1';
                const element = document.getElementById('features1');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000); // Delay of 1 second to ensure the download starts before redirecting

           
        } catch (error) {
            console.error('Error processing trial download:', error);
            setError(error.message || 'An error occurred. Please try again.');
        }
    };

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'GB',
                currency: 'gbp',
                total: {
                    label: 'Lifetime License',
                    amount: 2900,
                },
                requestPayerEmail: true,
                requestPayerName: true,
                
               
            });

            pr.canMakePayment().then(result => {
                if (result) {
                    setPaymentRequest(pr);
                }
            });

            pr.on('paymentmethod', async (e) => {
                try {
                    const { clientSecret } = await createPaymentIntent(e.payerEmail);
                    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                        clientSecret,
                        { payment_method: e.paymentMethod.id },
                        { handleActions: false }
                    );

                    if (confirmError) {
                        e.complete('fail');
                        setError(confirmError.message);
                    } else {
                        e.complete('success');
                        await handlePaymentSuccess(paymentIntent.id, e.payerEmail);
                    }
                } catch (error) {
                    e.complete('fail');
                    setError(error.message || 'An error occurred. Please try again.');
                }
            });
        }
    }, [stripe]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setIsProcessing(true);

        if (!stripe || !elements) {
            setIsProcessing(false);
            return;
        }

        try {
            const { clientSecret } = await createPaymentIntent(email);
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: { email },
                },
            });

            if (result.error) {
                throw result.error;
            } else {
                await handlePaymentSuccess(result.paymentIntent.id, email);
            }
        } catch (error) {
            console.error('Error:', error);
            setError(error.message || 'An error occurred. Please try again.');
        } finally {
            setIsProcessing(false);
        }
    };

    const createPaymentIntent = async (email) => {
        const response = await fetch('/create-payment-intent', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });

        if (!response.ok) {
            throw new Error('Failed to create payment intent');
        }

        return response.json();
    };

    const handlePaymentSuccess = async (paymentIntentId, email) => {
        const processResponse = await fetch('/process-payment', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                paymentIntentId,
                email,
            }),
        });

        if (!processResponse.ok) {
            throw new Error('Failed to process payment on server');
        }

        const processResult = await processResponse.json();
        if (processResult.success) {
            setPaymentStatus('Payment successful! Check your email for the license key.');
            setEmail('');
            elements.getElement(CardElement).clear();

            // Add Facebook Pixel purchase tracking
            const finalPrice = 29; // The price is fixed at 29 GBP
            if (window.trackFacebookPurchase) {
                window.trackFacebookPurchase(finalPrice);
            }
        } else {
            throw new Error('Payment processed, but license generation failed');
        }
    };

    return (
        <>



            <div className="container max-w-md mx-auto p-1 bg-black bg-opacity-10 backdrop-blur rounded-lg mb-4">
                <h2 className="text-lg font-bold text-white text-center mb-4 mt-2">PURCHASE LICENSE KEY </h2> 
                <div className="flex items-center">
                    <div className="w-1/6 flex justify-center ">
                    </div>
                    <ul className="text-white text-center text-sm mb-6 w-4/6">
                        <li className="flex items-center justify-center mb-0">
                            <CreditCard className="w-4 h-4 mr-2 text-gray-500" />
                            One-time payment
                        </li>
                        <li className="flex items-center justify-center mb-0">
                            <Key className="w-4 h-4 mr-2 text-gray-500" />
                            Lifetime access to all features
                        </li>
                        <li className="flex items-center justify-center mb-0">
                            <Zap className="w-4 h-4 mr-2 text-gray-500" />
                            Free updates and support
                        </li>
                        <li className="flex items-center justify-center mb-0">
                            <Infinity className="w-4 h-4 mr-2 text-gray-500" />
                            Unlimited MT5 instances
                        </li>
                        <li className="flex items-center justify-center mb-0">
                            <Monitor className="w-4 h-4 mr-2 text-gray-500" />
                            For a single computer only
                        </li>
                        <li className="flex items-center justify-center mb-0">
                            <Mail className="w-4 h-4 mr-2 text-gray-500" />
                            Instant email delivery
                        </li>
                    </ul>
                    <div className="w-1/6 flex justify-center">
                    </div>
                </div>
            </div>



            <div className="container max-w-md mx-auto p-6 bg-black bg-opacity-40 backdrop-blur-md rounded-lg shadow-lg mb-4">
                <h1 className="text-2xl font-bold text-white text-center mb-4"></h1>
               
                {paymentRequest && (
                    <div className="mb-4">
                        <PaymentRequestButtonElement options={{ paymentRequest }} />
                    </div>
                )}


                {paymentRequest && (
                    <div className="text-xs text-white text-center m-4">
                        OR
                    </div>
                )}

                <form id="payment-form" onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                            className="w-full px-3 py-2 text-white bg-black bg-opacity-50 rounded border border-gray-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 outline-none"
                        />
                    </div>
                    <div id="card-element" className="p-3 bg-black bg-opacity-50 rounded border border-gray-600">
                        <CardElement
                            options={{
                                style: {
                                    base: {
                                        color: '#ffffff',
                                        fontFamily: 'Arial, sans-serif',
                                        fontSmoothing: 'antialiased',
                                        fontSize: '16px',
                                        '::placeholder': {
                                            color: '#aab7c4'
                                        },
                                        iconColor: '#ffffff'
                                    },
                                    invalid: {
                                        color: '#e74c3c',
                                        iconColor: '#e74c3c'
                                    }
                                }
                            }}
                        />
                    </div>
                    <div id="card-errors" role="alert" className="text-red-500 text-xs">
                        {error}
                    </div>
                    <HoverBorderGradient
                        containerClassName="rounded-lg border-2 border-gray-500 w-full"
                        as="button"
                        type="submit"
                        disabled={!stripe || isProcessing}
                        className="bg-black text-white flex items-center justify-center text-xs space-x-2 px-4 py-2 w-full"
                    >
                        {isProcessing ? (
                            <span>PROCESSING, PLEASE WAIT...</span>
                        ) : (
                            <span>
                                PAY 29{'\u00A3'}{' '}
                                <span style={{ textDecoration: 'line-through', marginLeft: '0.2em' }}>
                                    100{'\u00A3'}
                                </span>
                            </span>
                        )}
                    </HoverBorderGradient>
                </form>
                <div id="payment-status" className={`mt-4 text-center text-xs font-bold ${paymentStatus ? 'text-white' : ''}`}>
                    {paymentStatus}
                </div>
            </div>



            <div className="container max-w-md mx-auto p-1 bg-black bg-opacity-10 backdrop-blur rounded-lg mb-4">
                <h2 className="text-lg font-bold text-white text-center mb-4 mt-2">5 DAYS FREE TRIAL</h2>

                <div className="flex items-center">
                    <div className="w-1/6 flex justify-center ">
                    </div>
                    <ul className="text-white text-center text-sm mb-6 w-4/6">
                        <li className="flex items-center justify-center mb-0">
                            <Clock className="w-4 h-4 mr-2 text-gray-500" />
                            Try before you buy
                        </li>
                        <li className="flex items-center justify-center mb-0">
                            <CreditCard className="w-4 h-4 mr-2 text-gray-500" />
                            No credit card required
                        </li>
                        <li className="flex items-center justify-center mb-0">
                            <Key className="w-4 h-4 mr-2 text-gray-500" />
                            Full access to all features
                        </li>
                        <li className="flex items-center justify-center mb-0">
                            <Monitor className="w-4 h-4 mr-2 text-gray-500" />
                            One trial per computer
                        </li>
                    </ul>
                    <div className="w-1/6 flex justify-center">
                    </div>
                </div>
            </div>



            <div className="container max-w-md mx-auto p-6 bg-black bg-opacity-40 backdrop-blur-md rounded-lg shadow-lg mb-10">
                <h1 className="text-2xl font-bold text-white text-center mb-4"></h1>
               
                <form onSubmit={handleTrialDownload} className="flex space-x-4">
                    <input
                        type="email"
                        value={trialEmail}
                        onChange={(e) => setTrialEmail(e.target.value)}
                        placeholder="Email"
                        required
                        className="flex-grow px-3 py-2 text-white bg-black bg-opacity-50 rounded border border-gray-600 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 outline-none min-w-0"
                    />
                    <HoverBorderGradient
                        containerClassName="rounded-lg border-2 border-gray-500"
                        as="button"
                        type="submit"
                        className="bg-black text-white flex items-center justify-center text-xs px-4 py-2 whitespace-nowrap"
                    >
                        <span>DOWNLOAD</span>
                    </HoverBorderGradient>
                </form>
                {downloadSuccess && (
                    <p className="text-white font-bold text-xs text-center mt-4">
                        Download successful! Redirecting to installation instructions...
                    </p>
                )}
            </div>
        </>
    );
};

const StripePaymentForm = () => {
    return (
        <div className="mx-5 md:mx-0 my-5 md:my-0">  {/* Added margin classes here */}
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </div>
    );
};

export default StripePaymentForm;