import React from "react";
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";
import { HoverBorderGradient } from "./ui/hover-border-gradient.tsx";
import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";

export default function TermsAndConditions() {
    return (

        <>
            <Helmet>
                <title>Terms and Conditions | Flow Execution - Clear Guidelines for Trading Tools</title>
                <meta name="description" content="Understand the terms of using Flow Execution's trading tools for MetaTrader 5. Our clear conditions ensure a fair and transparent trading experience for all users." />
                <meta name="keywords" content="Flow Execution, terms and conditions, trading guidelines, MetaTrader 5, forex trading, CFD trading" />
                <meta property="og:title" content="Flow Execution Terms and Conditions - Fair Trading Practices" />
                <meta property="og:description" content="Read Flow Execution's terms and conditions for using our MetaTrader 5 tools. Ensure compliance and understand your rights in forex and CFD trading." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.flowexecution.com/terms-and-conditions" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Flow Execution Terms - Clarity in Trading Tools Usage" />
                <meta name="twitter:description" content="Flow Execution's terms and conditions provide clear guidelines for using our MetaTrader 5 tools in forex and CFD trading. Know your rights and responsibilities." />
            </Helmet>


        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="px-7 sm:px-0 py-16"
        >
            <div className="max-w-4xl mx-auto">
                <h1 className="text-2xl font-bold text-white mb-8 text-center">TERMS AND CONDITIONS</h1>
                <div className="relative z-10 bg-zinc-900 bg-opacity-40 rounded-lg shadow-lg overflow-hidden border border-zinc-700">
                    <div className="p-8 space-y-6 text-white backdrop-blur-sm">
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
                            <p>By accessing or using the Flow Execution Expert Advisor (EA) for MetaTrader 5, you agree to be bound by these Terms and Conditions. If you do not agree to these terms, please refrain from using our software.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">2. License Grant</h2>
                            <p>Subject to these Terms, Flow Execution grants you a limited, non-exclusive, non-transferable license to use the EA on your MetaTrader 5 platform for your personal or internal business purposes.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">3. Intellectual Property</h2>
                            <p>All intellectual property rights in the EA, including but not limited to copyrights, patents, trade secrets, and trademarks, are owned by Flow Execution. You acknowledge that you have no rights in or to the EA other than the right to use it in accordance with these Terms.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">4. Use Restrictions</h2>
                            <p>You agree not to: (a) modify, adapt, or create derivative works of the EA; (b) reverse engineer, decompile, or disassemble the EA; (c) rent, lease, lend, sell, sublicense, or otherwise transfer rights to the EA; or (d) remove or alter any proprietary notices on the EA.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">5. Payment and Refunds</h2>
                            <p>Payment for the EA is due in full at the time of purchase. We maintain a strict no-refund policy except in cases where video evidence clearly demonstrates a malfunction of the EA on your specific system under normal operating conditions. The decision to issue a refund in such cases is at our sole discretion.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">6. Disclaimer of Warranties</h2>
                            <p>The EA is provided "as is" and "as available" without warranty of any kind. While we strive for excellence, we do not warrant that the EA will meet your specific requirements or that its operation will be uninterrupted or error-free.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">7. Limitation of Liability</h2>
                            <p>To the fullest extent permitted by applicable law, Flow Execution shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your use or inability to use the EA.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">8. Termination</h2>
                            <p>We reserve the right to terminate or suspend your access to the EA at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users of the EA, us, or third parties, or for any other reason.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">9. Governing Law</h2>
                            <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Flow Execution is established, without regard to its conflict of law principles.</p>
                        </section>
                        <section>
                            <h2 className="text-2xl font-semibold mb-4">10. Changes to Terms</h2>
                            <p>We reserve the right to modify these Terms at any time. We will provide notice of significant changes by posting an announcement on our website. Your continued use of the EA after such modifications constitutes your acceptance of the updated Terms.</p>
                        </section>
                    </div>

                </div>

                    <div className="mt-8 flex justify-center">
                        <Link to="/">
                            <HoverBorderGradient
                                containerClassName="rounded-lg border-2 border-gray-500"
                                as="button"
                                className="bg-black text-white flex items-center text-xs space-x-2 px-4 py-2"
                            >
                                <ArrowLeft className="h-4 w-4" />
                                <span>BACK</span>
                            </HoverBorderGradient>
                        </Link>
                    </div>



                
            </div>
            </motion.div>
        </>
    );
}